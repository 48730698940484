import { Pipe, PipeTransform } from "@angular/core";
import { QualificationType } from "@app/models/role";
import { AuthService } from "@app/services/auth.service";
import { DecoStoreService } from "@app/stores/deco.store";
import { map, Observable } from "rxjs";
import { RoleService } from '../services/role.service';

@Pipe({
    name: "roleName",
    standalone: true,
})
export class RoleNamePipe implements PipeTransform {
    constructor(private decoStoreService: DecoStoreService, private authService: AuthService) {}

    transform(value: any): Observable<string> {
        return this.decoStoreService.getRoles().pipe(map((res) => res.find((x) => x.value === value)?.text || ""));
    }
}
