<div class="bg-neutral">
    <div class="container">
        <h2>{{ username ? username : ("it.portal.users.new" | translate) }}</h2>
        <form [formGroup]="userForm" class="pt-5">
            <div class="row">
                <div class="col-lg-6">
                    <!-- @if(isProfileView) { -->
                    <it-input
                        type="text"
                        [label]="'it.portal.users.cf' | translate"
                        formControlName="cf"
                        uppercase
                    ></it-input>
                    <!-- } @else {
                    <it-autocomplete
                        [label]="'it.portal.users.cf' | translate"
                        formControlName="cf"
                        [forceShowLabel]="true"
                        uppercase
                        [autocompleteData]="userService.searchCF"
                        (autocompleteSelectedEvent)="onUserSelected($event)"
                    />
                    } -->
                </div>
            </div>
        </form>
    </div>
</div>
<div class="bg-white">
    <div class="container">
        <div class="details-container py-5" [class.collapsed]="collapsedSidebar">
            <div class="details-sidebar">
                <it-sidebar>
                    <div class="sidebar-title">
                        <span class="d-flex"
                            ><a (click)="toggleSidebar()" class="material-symbols-outlined me-2"> menu </a>@if
                            (!collapsedSidebar) {
                            {{ "it.portal.users.detail" | translate }}
                            }</span
                        >
                        @if (!collapsedSidebar) {<a (click)="toggleSidebar()"
                            ><span class="material-symbols-outlined"> chevron_left </span></a
                        >}
                    </div>
                    <it-list>
                        <it-list-item
                            ><a
                                [routerLink]="sidebarLink"
                                fragment="ruoli"
                                [class.active]="activeFragment === 'ruoli' || !activeFragment"
                                [attr.aria-label]="'it.portal.roles.label' | translate"
                                ><span
                                    class="material-symbols-outlined me-2"
                                    [itTooltip]="'it.portal.roles.label' | translate"
                                    [tooltipPlacement]="TooltipPlacement"
                                >
                                    group
                                </span>
                                @if (!collapsedSidebar){
                                {{ "it.portal.roles.label" | translate }}
                                }</a
                            ></it-list-item
                        >
                        @if(!isNew){
                        <it-list-item
                            ><a
                                [routerLink]="sidebarLink"
                                fragment="info"
                                [class.active]="activeFragment === 'info'"
                                [attr.aria-label]="'it.portal.users.data' | translate"
                                ><span
                                    class="material-symbols-outlined me-2"
                                    [itTooltip]="'it.portal.users.data' | translate"
                                    [tooltipPlacement]="TooltipPlacement"
                                >
                                    contact_page
                                </span>
                                @if (!collapsedSidebar){
                                {{ "it.portal.users.data" | translate }}
                                }</a
                            ></it-list-item
                        >}
                    </it-list>
                    <a (click)="goToList()" class="sidebar-footer"
                        ><span
                            class="material-symbols-outlined me-2"
                            [itTooltip]="'it.portal.return-to-list' | translate"
                            [tooltipPlacement]="TooltipPlacement"
                        >
                            arrow_back
                        </span>
                        @if (!collapsedSidebar) {
                        {{ (isProfileView ? "it.portal.come-back" : "it.portal.return-to-list") | translate }}
                        }</a
                    >
                </it-sidebar>
            </div>
            <div class="details-content" [id]="activeFragment">
                @switch (activeFragment) { @default {
                <div class="details-title">
                    <h4>{{ "it.portal.roles.label" | translate }}</h4>
                    @if(!isProfileView && (authService.hasAllQualifications([QualificationType.MANAGE_USERS]) | async)){
                    <div class="creation-tools">
                        <a (click)="addRole()"
                            ><span class="material-symbols-outlined me-2"> group_add </span
                            >{{ "it.portal.roles.add" | translate }}</a
                        >
                    </div>
                    }
                </div>
                <it-list class="custom-list">
                    @for (item of roles; track item.uid) {
                    <it-list-item iconLeft="true" class="list-data">
                        <div class="flex-grow-1">
                            <div class="list-title">
                                <a (click)="editRole(item)" class="text me-4">{{ item.ruolo | roleName | async }}</a>
                                @if(item.disabilitato){
                                <it-chip color="secondary" [label]="'it.portal.disabled' | translate"></it-chip>
                                } @if(item.uid === loggedUser.utenteEnteRuolo.uid){
                                <it-chip
                                    class="chip-version active"
                                    [label]="'it.portal.current' | translate"
                                ></it-chip>
                                }
                            </div>
                            <div class="list-info">
                                <span>{{ item.ente | institutionName | async }}</span>
                            </div>
                        </div>
                        <ng-container multiple>
                            <div class="actions">
                                @if(isProfileView || (authService.hasAllQualifications([QualificationType.MANAGE_USERS])
                                | async)){
                                <a
                                    (click)="editRole(item)"
                                    [attr.aria-label]="'it.portal.roles.edit' | translate"
                                    [itTooltip]="'it.portal.roles.edit' | translate"
                                    [tooltipPlacement]="TooltipPlacement"
                                >
                                    <span class="material-symbols-outlined"> group </span>
                                </a>
                                } @if(!isProfileView && item.uid !== loggedUser.utenteEnteRuolo.uid &&
                                (authService.hasAllQualifications([QualificationType.MANAGE_USERS]) | async)){
                                <a
                                    (click)="deleteRole(item)"
                                    [attr.aria-label]="'it.portal.roles.remove' | translate"
                                    [itTooltip]="'it.portal.roles.remove' | translate"
                                    [tooltipPlacement]="TooltipPlacement"
                                >
                                    <span class="material-icons-outlined">delete</span>
                                </a>
                                }
                            </div>
                        </ng-container>
                    </it-list-item>
                    } @empty {
                    <div class="text py-3">{{ "it.portal.no-results" | translate }}</div>
                    }
                </it-list>
                @if (isNew) {
                <div class="mt-5">
                    <div class="text-end">
                        <button type="submit" itButton="primary" (click)="onSubmit()" [disabled]="">
                            {{ "it.general.save" | translate }}
                        </button>
                    </div>
                </div>
                } } @case ('info') { @if (!isNew) {

                <div class="details-title">
                    <h4>{{ "it.portal.users.data" | translate }}</h4>
                </div>
                <form [formGroup]="userForm" class="pt-4">
                    <div class="row">
                        <div class="col-lg-6">
                            <it-input
                                type="text"
                                [label]="'it.portal.users.name' | translate"
                                formControlName="nome"
                            ></it-input>
                        </div>
                        <div class="col-lg-6">
                            <it-input
                                type="text"
                                [label]="'it.portal.users.surname' | translate"
                                formControlName="cognome"
                            ></it-input>
                        </div>
                    </div>
                </form>
                @if(isTechnician){
                <form [formGroup]="infoForm">
                    <div class="row">
                        <div class="col-lg-8">
                            <it-input
                                type="text"
                                [label]="'it.portal.address' | translate"
                                formControlName="indirizzo"
                            ></it-input>
                        </div>
                        <div class="col-lg-4">
                            <it-autocomplete
                                [label]="'it.portal.municipality' | translate"
                                formControlName="comune"
                                [forceShowLabel]="true"
                                [autocompleteData]="decoService.searchMunicipalities"
                                (autocompleteSelectedEvent)="onMunicipalitySelected($event)"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <it-input
                                type="text"
                                [label]="'it.portal.users.professional-qualification' | translate"
                                formControlName="abilitazioneProfessionale"
                            ></it-input>
                        </div>
                        <div class="col-lg-4">
                            <it-input
                                type="date"
                                [label]="'it.portal.users.professional-qualification-date' | translate"
                                formControlName="abilitazioneProfessionaleData"
                            ></it-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <it-input
                                type="text"
                                [label]="'it.portal.users.register' | translate"
                                formControlName="albo"
                            ></it-input>
                        </div>
                        <div class="col-lg-2">
                            <it-select
                                [label]="'it.portal.province' | translate"
                                formControlName="provinciaAlbo"
                                [options]="provices"
                                [defaultOption]="
                                    'it.portal.select'
                                        | translate : { elem: ('it.portal.province' | translate).toLowerCase() }
                                "
                            >
                            </it-select>
                        </div>
                        <div class="col-lg-4">
                            <it-input
                                type="text"
                                [label]="'it.portal.users.registration-number' | translate"
                                formControlName="numeroIscrizione"
                            ></it-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <it-input
                                type="text"
                                [label]="'it.portal.phone' | translate"
                                formControlName="telefono"
                            ></it-input>
                        </div>
                        <div class="col-lg-6">
                            <it-input
                                type="text"
                                [label]="'it.portal.email' | translate"
                                formControlName="mail"
                            ></it-input>
                        </div>
                    </div>
                </form>
                } @if (!isEditView) {
                <div class="mt-5">
                    <div class="text-end">
                        <button type="submit" itButton="primary" (click)="onSubmit()" [disabled]="">
                            {{ "it.general.save" | translate }}
                        </button>
                    </div>
                </div>
                } } } }
            </div>
        </div>
    </div>
</div>

<it-modal #roleModal="itModal" [scrollable]="!isProfileView" size="lg">
    <ng-container modalTitle
        ><span class="material-symbols-outlined fs-2 me-2"> manage_accounts </span
        >{{ "it.portal.roles.edit" | translate }}</ng-container
    >
    <form [formGroup]="roleForm" class="pt-3">
        <div class="row">
            <div class="col-lg-6">
                <it-select
                    [label]="'it.portal.roles.role' | translate"
                    formControlName="ruolo"
                    [options]="rolesDeco"
                    [defaultOption]="
                        'it.portal.select' | translate : { elem: ('it.portal.roles.role' | translate).toLowerCase() }
                    "
                    (change)="onRoleChange(true)"
                >
                </it-select>
            </div>
            <div class="col-lg-6">
                <it-select
                    [label]="'it.portal.institutions.institution' | translate"
                    formControlName="ente"
                    [options]="institutionsDeco"
                    [defaultOption]="
                        'it.portal.select'
                            | translate : { elem: ('it.portal.institutions.institution' | translate).toLowerCase() }
                    "
                >
                </it-select>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <it-input type="text" [label]="'it.portal.phone' | translate" formControlName="telefono"></it-input>
            </div>
            <div class="col-lg-6">
                <it-input type="text" [label]="'it.portal.email' | translate" formControlName="mail"></it-input>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <it-checkbox
                    formControlName="dipendenteEnte"
                    [label]="'it.portal.users.institution-employee' | translate"
                    toggle="true"
                ></it-checkbox>
            </div>
            <div class="col-lg-6">
                <it-checkbox
                    formControlName="disabilitato"
                    [label]="'it.portal.disabled' | translate"
                    toggle="true"
                ></it-checkbox>
            </div>
        </div>
    </form>
    @if(!isProfileView && roleForm.get('uid')?.value !== loggedUser.utenteEnteRuolo.uid){
    <app-qualification-list
        [listTitle]="'it.portal.qualifications.label' | translate"
        [(data)]="selectedRole.utentiAbilitazioni"
        [options]="roleQualifications"
    ></app-qualification-list>
    }

    <ng-container footer>
        <button itButton="primary" type="button" (click)="saveRole()">
            {{ (isNew ? "it.core.confirm" : "it.general.save") | translate }}
        </button>
        <button itButton="outline-primary" type="button" data-bs-dismiss="modal">
            {{ "it.general.abort" | translate }}
        </button>
    </ng-container>
</it-modal>
